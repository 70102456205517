import axios from "axios";
import { BAD_REQUEST, NOT_FOUND, SOMETHING_WENT_WRONG_API, UNAUTHORISED } from "../utils/constants/GlobalConstants";
let baseURL = process.env.REACT_APP_API_BASE_URL;
export const axiosClient = axios.create();
axiosClient.defaults.baseURL = baseURL;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  crossDomain: true,
  "Access-Control-Allow-Headers":
    "DNT,User-Agent,If-Modified-Since,Cache-Control,Content-Type,Range,origin, x-requested-with, accept",
};

export function getRequest(URL) {
  return axiosClient
    .get(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      if(error?.status === 500){
        error.message= SOMETHING_WENT_WRONG_API
      } else if(error?.status === 400){
        error.message= BAD_REQUEST;
      } else  if(error?.status === 403 || error?.status === 401){
        error.message= UNAUTHORISED;
      }  if(error?.status === 404){
        error.message= NOT_FOUND;
      }
      throw error;
    });
}

export function postRequest(URL, payload) {
  return axiosClient
    .post(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => {
      if(error?.status === 500){
        error.message= SOMETHING_WENT_WRONG_API
      } else if(error?.status === 400){
        error.message= BAD_REQUEST;
      } else  if(error?.status === 403 || error?.status === 401){
        error.message= UNAUTHORISED;
      }  if(error?.status === 404){
        error.message= NOT_FOUND;
      }
      throw error;
    });
}

import * as React from "react";
import { Box, IconButton, Popover } from "@mui/material";
import { InfoIcon } from "lucide-react";
import { TextTypography } from "../typography/TextTypography";
import {
  DISPLAY_FLEX_END,
  FLEX_DIRECTION_ROW,
} from "../../../utils/constants/GlobalConstants";

export default function HoverPopover(prop) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ ...FLEX_DIRECTION_ROW, ...DISPLAY_FLEX_END }}>
      <IconButton
        testid="popover"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <TextTypography
          sx={{
            p: 1,
            width: "200px",
            maxWidth: "100%",
            wordWrap: "noWrap",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily:
              "ui-sans-serif, system-ui, sans-serif, Segoe UI Emoji,Segoe UI Symbol, Noto Color Emoji !important",
          }}
          text={prop.popoverTxt}
        ></TextTypography>
      </Popover>
    </Box>
  );
}

/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from "react";
import TextInput from "../common/textInput/TextInput";
import {
  ERR_MSG_PART_NUMBER,
  FILTER_SEARCH_TYPE,
  PARTIAL,
  PARTIAL_SEARCH_TYPE,
  PARTNUM_SEARCH,
  SEARCH_FILTER_PARTIAL_PLACEHOLDER,
  SEARCH_FILTER_PLACEHOLDER,
  ERR_DUPE_PART_NUMBER
} from "../../utils/constants/GlobalConstants";
import { VALID_SEARCH_REGEX } from "../../utils/constants/GlobalFormConstants";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import { competitorInterchangeData } from "../../services/ProductServices";
import SimpleBackdrop from "../common/backdrop/BackDrop";

const SearchByPart = memo(({ partNum, filterNum, setPartNum, setfilterNum, isValid, setIsValid, ...props }) => {
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (props.reset !== PARTIAL_SEARCH_TYPE) {
      setPartNum("");
    }
    if (props.reset !== FILTER_SEARCH_TYPE) {
      setfilterNum("");
    }
  }, [props.reset]);
  const regex = VALID_SEARCH_REGEX;

  const handlePartChange = (e) => {
    const val = e.target.value;
    const valid = val ? regex.test(val) : true;
    setIsValid(valid);
    setPartNum(e.target.value);
    setfilterNum("");
  };
  const handleFilterChange = (e) => {
    const val = e.target.value;
    const valid = val ? regex.test(val) : true;
    setIsValid(valid);
    setfilterNum(e.target.value);
    setPartNum("");
  };

  const getPartData = async (searchArray) => {
    try {
      const res = await competitorInterchangeData({
        partNumbers: searchArray,
        apiName: props?.partial ? PARTIAL : PARTNUM_SEARCH,
      });
      return res;
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };
  const onPressAdd = async (e) => {
    const inputNum = props.partial ? partNum : filterNum;
    const values = inputNum.split(',').map(val => val.trim()).filter(Boolean);
    const uniqVal = new Set(values);
    let validSearch = isValid;
    let hasDupes = false;
    if (values.length !== uniqVal.size) {
      setIsValid(false);
      validSearch = false;
      hasDupes = true;
    }
    setLoader(true);
    e.preventDefault();
    if (validSearch) {
      let searchArray = props?.partial
        ? partNum.split(",")
        : filterNum.split(",");
      let res = "";
      if (searchArray?.length > 0 && searchArray[0]) {
        res = await getPartData(searchArray);
      }
      const partSearch = props.partial ? partNum : filterNum;

      const data = {
        res: res?.partNumberDetailsList,
        val: true,
        searchType: props.partial ? PARTIAL_SEARCH_TYPE : FILTER_SEARCH_TYPE,
        partSearch,
        searchArray,
        partial: props?.partial,
      };
      setLoader(false);
      props.handleSearch(data);
    } else {
      let msg = hasDupes ? ERR_DUPE_PART_NUMBER : ERR_MSG_PART_NUMBER;
      setApiError((val) => ({
        ...val,
        val: true,
        message: msg,
      }));
      setLoader(false);
    }
  };

  return (
    <div className="zoom">
      <SimpleBackdrop open={loader} />
      <form className="lg: mb-4 mt-2">
        <div className="flex rounded-lg overflow-hidden">
          {props.partial ? (
            <TextInput
              className="min-h-[48px] focus:outline-none grow px-4 bg-[#a3bef124] text-sm"
              type={"text"}
              placeholder={SEARCH_FILTER_PARTIAL_PLACEHOLDER}
              required={true}
              value={partNum}
              error={!isValid}
              style={{
                borderBottom: isValid ? "inherit" : "1px solid red",
              }}
              onChange={(e) => handlePartChange(e)}
            />
          ) : (
            <TextInput
              className="min-h-[48px] focus:outline-none grow px-4 bg-[#a3bef124] text-sm"
              type={"text"}
              placeholder={SEARCH_FILTER_PLACEHOLDER}
              required={true}
              error={!isValid}
              style={{
                borderBottom: isValid ? "inherit" : "1px solid red",
              }}
              value={filterNum}
              onChange={(e) => handleFilterChange(e)}
            />
          )}
          <button
            className="bg-blue-600 text-white hover:bg-opacity-90 min-h-[48px] px-4"
            type="submit"
            onClick={(e) => onPressAdd(e)}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </form>
      <SimpleSnackbar apiError={apiError} />
    </div>
  );
});

export default SearchByPart;
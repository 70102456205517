import { Box, FormControl } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../common/textInput/TextInput";
import ButtonLayout from "../common/button/ButtonLayout";
import {
  MAKE_LABEL,
  MODEL_LABEL,
  MT_6,
  QUICK_SEARCH_FILTER_HEADER,
  QUICK_SEARCH_TYPE,
  SEARCH_LABEL,
  WIDTH_100_px,
  YEAR_LABEL,
} from "../../utils/constants/GlobalConstants";
import {
  AIR_FILTER,
  BREATHER_FILTER,
  CABIN_AIR_FILTER,
  FUEL_FILTER,
  HYDRAULIC_FILTER,
  OIL_FILTER,
  OTHERS,
  TRANSMISSION_FILTER,
} from "../../utils/constants/GlobalConstants";

import { TextTypography } from "../common/typography/TextTypography";
import SimpleBackdrop from "../common/backdrop/BackDrop";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import {
  QUICK_SEARCH_DATA,
  VALID_SEARCH_REGEX_ONLY_ALPHABETS,
  VALID_SEARCH_REGEX_ONLY_NUMBER
} from "../../utils/constants/GlobalFormConstants";
import { getQuickSearchData } from "../../services/ProductServices";
const empObj = { modelName: "", make: "", year: "" };
const QuickSearch = (props) => {
  const [quickSearchInputs, setQuickSearchInputs] = useState(empObj);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [arrVal, setArrVal] = useState(QUICK_SEARCH_DATA);
  const getQuickSearchResult = async (searchArray) => {
    try {
      const res = await getQuickSearchData({
        year: quickSearchInputs?.year,
        make: quickSearchInputs?.make?.trim(),
        model: quickSearchInputs?.modelName?.trim(),
        apiName: "quickSearch",
        apiType: "vehicleQuickSearch",
      });
      setLoader(false);
      return res;
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
      setLoader(false);
    }
  };
  React.useEffect(() => {
    if (props.reset !== QUICK_SEARCH_TYPE) {
      setQuickSearchInputs(empObj);
    }
  }, [props.reset]);
  const handleQuickSearchInputs = (e, key, index) => {
    let regex =
      key === "year" ? VALID_SEARCH_REGEX_ONLY_NUMBER : VALID_SEARCH_REGEX_ONLY_ALPHABETS;
    const val = e.target.value;
    let valid = val ? regex.test(val) : true;
    let valArr = [...arrVal];
    valArr[index].valid = valid;
    valArr[index].value = key;
    setArrVal(valArr);
    // setIsValid(valid);
    setQuickSearchInputs((inputs) => ({
      ...inputs,
      [key]: e.target.value,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onPressAdd();
    }
  };
  const onPressAdd = async () => {
    setLoader(true);

    if (arrVal[0].valid && arrVal[1].valid && arrVal[2].valid) {
      const temp = [];
      if (quickSearchInputs?.modelName || quickSearchInputs?.make || quickSearchInputs?.year) {

        try {
          const result = await getQuickSearchResult();
          const res =
            result && result["vehicleEngines"] &&
            result?.vehicleEngines?.map((item) => ({
              engineName: item.engineName,
              vehicleInfo: item.vehicleInfo,
              partsAndTypes: item.partsAndTypes,
            }));
          res &&
            res.length > 0 &&
            res.forEach((item) => {
              temp.push({
                type: "quickSearch",
                makeModel: item?.vehicleInfo,
                qualifier: item?.engineName,
                oil: item.partsAndTypes.hasOwnProperty(OIL_FILTER)
                  ? item.partsAndTypes[OIL_FILTER]
                  : "",
                fuel: item.partsAndTypes.hasOwnProperty(FUEL_FILTER)
                  ? item.partsAndTypes[FUEL_FILTER]
                  : "",
                air: item.partsAndTypes.hasOwnProperty(AIR_FILTER)
                  ? item.partsAndTypes[AIR_FILTER]
                  : "",
                cAir: item.partsAndTypes.hasOwnProperty(CABIN_AIR_FILTER)
                  ? item.partsAndTypes[CABIN_AIR_FILTER]
                  : "",
                trans: item.partsAndTypes.hasOwnProperty(TRANSMISSION_FILTER)
                  ? item.partsAndTypes[TRANSMISSION_FILTER]
                  : "",
                breather: item.partsAndTypes.hasOwnProperty(BREATHER_FILTER)
                  ? item.partsAndTypes[BREATHER_FILTER]
                  : "",
                hydraulic: item.partsAndTypes.hasOwnProperty(HYDRAULIC_FILTER)
                  ? item.partsAndTypes[HYDRAULIC_FILTER]
                  : "",
                others: item.partsAndTypes.hasOwnProperty(OTHERS)
                  ? item.partsAndTypes[OTHERS]
                  : "",
              });
            });
        }
        catch (err) {
          setApiError((val) => ({ ...val, val: true, message: "Something went wrong Try later" }));
          setLoader(false);
        }

      }
      const modelMakeYear = [quickSearchInputs.modelName, quickSearchInputs.make, quickSearchInputs.year].filter(val => val).join('_')
      const data = {
        val: true,
        searchType: "quickSearch",
        partNum: quickSearchInputs?.make || quickSearchInputs?.modelName || quickSearchInputs?.year,
        result: temp,
        searchString: modelMakeYear
      };
      props.handleSearch(data);
    } else {
      setApiError((val) => ({
        ...val,
        val: true,
        message: "Please Enter correct value",
      }));
    }
    setLoader(false);
  };
  return (
    <div className="zoom">
      <SimpleBackdrop open={loader} />
      <Box component="fieldset" className="fieldset-style-main">
        <form className="lg:mx-4  " style={{ marginBottom: "5px" }}>
          <TextTypography text={QUICK_SEARCH_FILTER_HEADER} />
          <FormControl fullWidth>
            <TextInput
              type={"text"}
              placeholder={MAKE_LABEL}
              value={quickSearchInputs?.make}
              required={true}
              onChange={(e) => handleQuickSearchInputs(e, "make", 0)}
              onKeyPress={handleKeyPress}
              error={!arrVal[0]?.valid}
              style={{
                borderBottom: arrVal[0]?.valid ? "inherit" : "1px solid red",
              }}
            />
            <TextInput
              testid="quick1"
              type={"text"}
              placeholder={MODEL_LABEL}
              label={MODEL_LABEL}
              required={true}
              value={quickSearchInputs?.modelName}
              onChange={(e) => handleQuickSearchInputs(e, "modelName", 1)}
              onKeyPress={handleKeyPress}
              style={{
                borderBottom: arrVal[1]?.valid ? "inherit" : "1px solid red",
              }}
              error={!arrVal[1]?.valid}
            />
            <TextInput
              type={"text"}
              placeholder={YEAR_LABEL}
              value={quickSearchInputs?.year}
              required={true}
              onChange={(e) => handleQuickSearchInputs(e, "year", 2)}
              onKeyPress={handleKeyPress}
              error={!arrVal[2]?.valid}
              style={{
                borderBottom: arrVal[2]?.valid ? "inherit" : "1px solid red",
              }}
            />
          </FormControl>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonLayout
              label={SEARCH_LABEL}
              sx={{ ...MT_6, ...WIDTH_100_px }}
              onClickBtn={() => onPressAdd()}
            />
          </Box>
        </form>
      </Box>
      <SimpleSnackbar apiError={apiError} />
    </div>
  );
};

export default QuickSearch;

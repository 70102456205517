import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import {
  APPBAR_STYLES,
  COLOR_BLACK,
  DISPLAY_IMG_MD,
  DISPLAY_IMG_XS,
  DISPLAY_MENU,
  FLEX_GROW_1,
  MR_2,
  MT_1,
  NAVBAR_CONTAINER,
  NavBtn,
  NavBtnPos,
  PT1,
  SUB_COLOR,
} from "../../../utils/constants/GlobalConstants";
import homeImg from "../../../assets/images/logo1.png";

function Navbar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const headerNavigations = (page) => {
    if (page === "Product Comparison") {
      navigate("/filter-tool/product-comparison");
    }
    if (page === "Home") {
      navigate("/filter-tool/Home");
      window.location.reload();
    }
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    headerNavigations(page);
  };

  return (
    <AppBar position="static" sx={APPBAR_STYLES} elevation={0}>
      <Container sx={{ ...NAVBAR_CONTAINER }}>
        <Toolbar disableGutters>
          <Box sx={{ ...DISPLAY_IMG_MD, ...MR_2, ...PT1 }}>
            <img
              src={homeImg}
              onClick={() => headerNavigations("Home")}
              style={{ pointerEvents: "all" }}
              alt="no-img-main"
              height="60px"
              width="50px"
            />
          </Box>

          <Box sx={{ ...FLEX_GROW_1, ...DISPLAY_IMG_XS }}>
            <IconButton
              size="large"
              testid="nav"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              testid="men"
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                ...DISPLAY_MENU,
                color: SUB_COLOR,
              }}
            >
              {props &&
                props.pages.map((page, index) => (
                  <MenuItem
                    key={index}
                    testid="menu-item"
                    onClick={() => handleCloseNavMenu(page)}
                  >
                    <Typography textAlign="center" sx={{ ...COLOR_BLACK }}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Box sx={{ ...DISPLAY_IMG_XS, ...MR_2, ...MT_1 }}>
            <img
              src={homeImg}
              alt="no-img"
              height="35px"
              width="40px"
              testid="img"
              onClick={() => headerNavigations("Home")}
            />
          </Box>

          <Box
            sx={{
              ...NavBtnPos,
            }}
          >
            {props &&
              props.pages.map((page, key) => (
                <>
                  <Button
                    key={key}
                    onClick={() => headerNavigations(page)}
                    className="navBtn"
                    sx={{
                      ...NavBtn,
                    }}
                  >
                    {page}
                  </Button>
                </>
              ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
